import { StatisticsApi } from "@/api"
import { FetchKpiInput } from "@/api/statistics"
import { AnalyticsReputationData } from "@/types/statistic"
import { checkFormatOfDateMonthDay } from "@/utils"
import dayjs from "dayjs"
import { t } from "i18next"
import { ChartService } from ".."

interface PropsSerie {
    name?: string
    data: number[]
    type?: string
}

class StatisticReputationService {
    fetchKpiReputation = async (filterParams: FetchKpiInput) => {
        return await StatisticsApi.fetchKpiReview(filterParams)
    }

    resolveGraphsAverageGrade = (graph: AnalyticsReputationData, type: string, locale: string) => {
        let maxPeriod = 0
        const categories = []
        const pourcentages = []
        const totalCurrent = []
        const period = graph.period
        const previousPeriod = graph?.previous_period ?? []
        const format = type === "month" ? checkFormatOfDateMonthDay(locale) : "MMM YY"
        const lengthTotal = type === "month" ? 31 : 13
        let minKey = period.length === lengthTotal ? 1 : 0

        const average_grade = {
            name: t("REQUEST_OPINION.AVERAGE_GRADE".toUpperCase()),
            data: [],
            type: "column",
            color: "#FBC02C",
        }

        const total = {
            name: t("REQUEST_OPINION.TOTAL".toUpperCase()),
            data: [],
            type: "line",
            color: "#68738D",
            markers: {
                size: 6, // Set size to display markers
                lineType: "dotted", // Set lineType to "dotted" for dotted line markers
            },
        }

        for (let i = minKey; i < period.length; i++) {
            categories.push(dayjs(period[i].date).locale(locale).format(format))
            average_grade.data.push(period[i].review_rating_average)
            totalCurrent.push(period[i].review_rating_average)
        }

        for (let i = minKey; i < previousPeriod.length; i++) {
            let valueTotal = previousPeriod[i].review_rating_average
            if (previousPeriod[i].review_rating_average === maxPeriod && maxPeriod === 0) {
                valueTotal = null
            } else if (previousPeriod[i].review_rating_average !== 0) {
                maxPeriod = previousPeriod[i].review_rating_average
            }

            total.data.push(valueTotal)
            pourcentages.push({
                review_rating_average: maxPeriod === 0 ? null : previousPeriod[i].review_rating_average_variation,
                total: maxPeriod === 0 ? null : previousPeriod[i].review_rating_average_variation,
            })
        }

        const maxYaxis = 5
        const minXaxis = 0
        const series: PropsSerie[] = [average_grade]

        if (previousPeriod?.length > 0) {
            series.push(total)
        }

        const stroke = {
            width: [0, 4],
            curve: "straight",
            dashArray: [0, 8],
        }

        const graphs = {
            series,
            categories,
            pourcentages,
            totalCurrent,
            stroke,
            maxYaxis: maxYaxis > 0 ? maxYaxis : 5,
            minXaxis,
        }

        const yFormatters = [
            ChartService.createFormatter("review_rating_average", graphs, false),
            ChartService.createFormatter("total", graphs, false),
        ]

        return { ...graphs, yFormatters, tooltip: { enabledOnSeries: [0] } }
    }

    resolveGraphsAReviewTreated = (graph: AnalyticsReputationData, type: string, locale: string) => {
        const categories = []
        const period = graph.period
        const previousPeriod = graph?.previous_period ?? []
        const format = type === "month" ? checkFormatOfDateMonthDay(locale) : "MMM YY"
        const lengthTotal = type === "month" ? 31 : 13
        const pourcentages = []
        const totalCurrent = []
        let minKey = period.length === lengthTotal ? 1 : 0
        let maxPeriod = 0

        const review_treated = {
            name: `${t("REQUEST_OPINION.RESPONSE_RATE".toUpperCase())} (%)`,
            data: [],
            type: "column",
            color: "#F9A824",
        }

        const total = {
            name: t("REQUEST_OPINION.TOTAL".toUpperCase()),
            data: [],
            type: "line",
            color: "#68738D",
            markers: {
                size: 6, // Set size to display markers
                lineType: "dotted", // Set lineType to "dotted" for dotted line markers
            },
        }

        for (let i = minKey; i < period.length; i++) {
            categories.push(dayjs(period[i].date).locale(locale).format(format))
            review_treated.data.push(period[i].review_treated)
            totalCurrent.push(period[i].review_treated)
        }
        for (let i = minKey; i < previousPeriod.length; i++) {
            let valueTotal = previousPeriod[i].review_treated
            if (previousPeriod[i].review_treated === maxPeriod && maxPeriod === 0) {
                valueTotal = null
            } else if (previousPeriod[i].review_treated !== 0) {
                maxPeriod = previousPeriod[i].review_treated
            }

            total.data.push(valueTotal)
            pourcentages.push({
                review_treated: maxPeriod === 0 ? null : previousPeriod[i].review_treated_variation,
                total: maxPeriod === 0 ? null : previousPeriod[i].review_treated_variation,
            })
        }

        const maxYaxis = 90
        const series: PropsSerie[] = [review_treated]

        if (previousPeriod?.length > 0) {
            series.push(total)
        }

        const stroke = {
            width: [0, 4],
            curve: "straight",
            dashArray: [0, 8],
        }

        const graphs = { series, categories, pourcentages, totalCurrent, stroke, maxYaxis: maxYaxis > 0 ? maxYaxis : 5 }

        const yFormatters = [
            ChartService.createFormatter("review_treated", graphs),
            ChartService.createFormatter("total", graphs),
        ]

        return { ...graphs, yFormatters, tooltip: { enabledOnSeries: [0] } }
    }

    resolveGraphsReviewCount = (graph: AnalyticsReputationData, type: string, locale: string) => {
        const isMonthFormat: boolean = type === "month"
        const categories = []
        const format = isMonthFormat ? checkFormatOfDateMonthDay(locale) : "MMM YY"
        const lengthTotal = isMonthFormat ? 31 : 13
        const period = graph.period
        const pourcentages = []
        const totalCurrent = []
        let lastReviews = 0
        const minKey = period.length === lengthTotal ? 1 : 0

        const review_count_last_month = {
            name: t("REQUEST_OPINION.NEW_REVIEWS".toUpperCase()),
            data: [],
            type: "column",
            color: "#F9A825",
        }

        const review_count = {
            name: t("REQUEST_OPINION.NEW_REVIEWS".toUpperCase()),
            data: [],
            type: "column",
            color: "#F47610",
        }

        const total = {
            name: t("REQUEST_OPINION.TOTAL".toUpperCase()),
            data: [],
            type: "line",
            color: "#68738D",
        }

        for (let i = minKey; i < period.length; i++) {
            let valueTotal = period[i].review_count
            lastReviews += i === 0 ? 0 : period[i - 1].review_count
            valueTotal = period[i].review_count + lastReviews
            categories.push(i % 2 === 0 || !isMonthFormat ? dayjs(period[i].date).locale(locale).format(format) : "")
            review_count_last_month.data.push(lastReviews)
            review_count.data.push(period[i].review_count)
            totalCurrent.push(valueTotal)
            total.data.push(valueTotal)
            pourcentages.push({
                review_count_last_month: null,
                review_count: null,
                total: Math.round((period[i].review_count * 100) / valueTotal),
            })
        }

        const maxYaxis = Math.max(...totalCurrent, ...total.data)
        const series: PropsSerie[] = [review_count_last_month, review_count]

        series.push(total)

        const stroke = {
            width: 0,
            curve: "straight",
            dashArray: 0,
        }

        const graphs = { series, categories, pourcentages, totalCurrent, stroke, maxYaxis: maxYaxis > 0 ? maxYaxis : 5 }

        const yFormatters = [
            ChartService.createFormatter("review_count_last_month", graphs),
            ChartService.createFormatter("review_count", graphs),
            ChartService.createFormatter("total", graphs),
        ]

        return {
            ...graphs,
            yFormatters,
            tooltip: { enabledOnSeries: [1, 2] },
            markers: {
                hover: {
                    size: 0,
                },
            },
            legend: {
                formatter: (seriesName, opts) => {
                    if ([0, 2].includes(opts.seriesIndex)) return "" // hides first label
                    return seriesName
                },
                markers: {
                    height: 12,
                    radius: 10,
                    width: [0, 12, 0], // hides some marker
                },
            },
        }
    }

    resolveGraphsFeedback = (graph: AnalyticsReputationData, type: string, locale: string) => {
        const isMonthFormat: boolean = type === "month"
        const categories = []
        const period = graph.period
        const previousPeriod = graph?.previous_period ?? []
        const format = isMonthFormat ? checkFormatOfDateMonthDay(locale) : "MMM YY"
        const lengthTotal = isMonthFormat ? 31 : 13
        const minKey = period.length === lengthTotal ? 1 : 0
        const pourcentages = []
        const totalCurrent = []
        let maxPeriod = 0

        const feedback_sms = {
            name: t("REQUEST_OPINION.SMS".toUpperCase()),
            data: [],
            type: "column",
            color: "#F87E0D",
        }
        const feedback_email = {
            name: t("REQUEST_OPINION.EMAIL".toUpperCase()),
            data: [],
            type: "column",
            color: "#FFA41B",
        }

        const total = {
            name: t("REQUEST_OPINION.TOTAL".toUpperCase()),
            data: [],
            type: "line",
            color: "#68738D",
        }

        for (let i = minKey; i < period.length; i++) {
            categories.push(i % 2 === 0 || !isMonthFormat ? dayjs(period[i].date).locale(locale).format(format) : "")
            feedback_sms.data.push(period[i].feedback_sms)
            feedback_email.data.push(period[i].feedback_email)
            totalCurrent.push(period[i].feedback_total)
        }

        for (let i = minKey; i < previousPeriod.length; i++) {
            let valueTotal = previousPeriod[i].feedback_total
            if (previousPeriod[i].feedback_total === maxPeriod && maxPeriod === 0) {
                valueTotal = null
            }
            if (previousPeriod[i].feedback_total !== 0) {
                maxPeriod = previousPeriod[i].feedback_total
            }

            total.data.push(valueTotal)
            pourcentages.push({
                feedback_sms: maxPeriod === 0 ? null : previousPeriod[i].feedback_sms_variation,
                feedback_email: maxPeriod === 0 ? null : previousPeriod[i].feedback_email_variation,
                total: maxPeriod === 0 ? null : previousPeriod[i].feedback_total_variation,
            })
        }

        const maxYaxis = Math.max(...totalCurrent, ...total.data)
        const series: PropsSerie[] = [feedback_sms, feedback_email]

        if (previousPeriod?.length > 0) {
            series.push(total)
        }

        const stroke = {
            width: [0, 0, 4],
            curve: "straight",
            dashArray: [0, 0, 8],
        }

        const graphs = { series, categories, pourcentages, totalCurrent, stroke, maxYaxis: maxYaxis > 0 ? maxYaxis : 5 }

        const yFormatters = [
            ChartService.createFormatter("feedback_sms", graphs),
            ChartService.createFormatter("feedback_email", graphs),
            ChartService.createFormatter("total", graphs),
        ]

        return { ...graphs, yFormatters }
    }
}
export default new StatisticReputationService()
