import { useMemo, useState } from "react"
import { Box, Modal, Paper, Typography, Button, Stack, IconButton } from "@mui/material"
import { useTranslation } from "react-i18next"
import {
    modalContainer,
    paperStyle,
    closeButton,
    titleStyle,
    subtitleStyle,
    textFieldStyle,
    cancelButton,
    saveButton,
} from "./style" // Import des styles
import Icon from "@/components/base/Icon"
import BaseInput from "@/components/base/input"
import { CompanyApi } from "@/api"

type AddAddressModalProps = {
    open: boolean
    handleClose: () => void
    companyId: string
    userUid: string
}

export const AddAddressModal: React.FC<AddAddressModalProps> = ({ open, handleClose, companyId, userUid }) => {
    const { t } = useTranslation()
    const [email, setEmail] = useState<string>("")

    const isMailValid = useMemo<boolean>(() => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        return email.length > 0 && emailRegex.test(email)
    }, [email])

    const handleSave = () => {
        CompanyApi.updateACompanyEmailSupport({ companyId, user_uid: userUid, email_support: email }).then(handleClose)
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={modalContainer}>
                <Paper elevation={6} sx={paperStyle}>
                    {/* Close button */}
                    <IconButton onClick={handleClose} sx={closeButton}>
                        <Icon name="x" />
                    </IconButton>

                    {/* Title */}
                    <Typography variant="h6" sx={titleStyle}>
                        {t("REPUTATION.ADD_RESPONSE_EMAIL")}
                    </Typography>

                    {/* Subtitle */}
                    <Typography variant="body2" sx={subtitleStyle}>
                        {t("REPUTATION.ADD_EMAIL_DETAILS")}
                    </Typography>

                    {/* Input Field */}
                    <BaseInput
                        fullWidth
                        type="email"
                        required
                        label={t("REPUTATION.REPLY_EMAIL")}
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={textFieldStyle}
                    />

                    {/* Action Buttons */}
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <Button onClick={handleClose} variant="outlined" color="primary" sx={cancelButton}>
                            {t("REPUTATION.CANCEL")}
                        </Button>
                        <Button
                            onClick={handleSave}
                            variant="contained"
                            color="primary"
                            sx={saveButton}
                            disabled={!isMailValid}
                        >
                            {t("REPUTATION.SAVE")}
                        </Button>
                    </Stack>
                </Paper>
            </Box>
        </Modal>
    )
}
