import { CompanyApi, LocationsApi } from "@/api"
import CampaignStore from "@/store/campaign"
import useLocationStore from "@/store/location"
import UserSessionStore from "@/store/user-session"
import { formattedTimezone } from "@/utils"
import { useCallback, useState } from "react"

const useBusinessHooks = () => {
    const { profile } = UserSessionStore()
    const { update, businesses } = useLocationStore()
    const { update: updateCampaign } = CampaignStore()
    const [companiesSupportWithLocation, setCompaniesSupportWithLocation] = useState<
        { locationId: string; email_support: string; companyId: string }[]
    >([])

    const fetchBusinessList = useCallback(
        async (
            fields = [
                "uid",
                "id",
                "logo",
                "address",
                "zip",
                "region",
                "country",
                "locationState",
                "newReviewUri",
                "companyId",
                "name",
                "gmbAccessId",
                "address",
                "city",
                "serviceArea",
                "timezone",
                "links",
            ]
        ) => {
            if (!profile?.uid) {
                return
            }
            if (businesses) {
                return businesses
            }
            const entities = await LocationsApi.fetchLocationsWithFields({
                user_uid: profile?.uid,
                fields,
            })
            const business = entities?.data ?? []

            const mappedEntities = [...business]?.map((location) => {
                return {
                    label: location?.name,
                    value: location?.id,
                    shortAddress: `${
                        location?.address !== undefined && !!location?.address ? location?.address + "," : ""
                    } ${location?.city !== undefined && !!location?.city ? location?.city : ""}`,
                    locationState: location?.locationState,
                    timezone: location?.timezone,
                    showPostAlert: location?.locationState?.showPostAlert ?? true,
                    links: location?.links,
                    logo: location?.logo,
                }
            })

            try {
                // Récupération des IDs des entreprises sans doublons
                const companyIds: string[] = business.map((item) => String(item.companyId))
                const promises = [...new Set(companyIds)].map(async (id) => {
                    const response = await CompanyApi.getACompanyEmailSupport({
                        companyId: id,
                        user_uid: profile?.uid,
                    })
                    return {
                        companyId: id,
                        email_support: response?.email_support || "", // Valeur par défaut vide si pas trouvé
                    }
                })

                // Attente des résultats des promesses
                const results = await Promise.all(promises)

                // Créer un tableau associant chaque locationId avec son email_support et companyId
                const emailMap = results.reduce((acc, { companyId, email_support }) => {
                    acc[companyId] = email_support
                    return acc
                }, {} as Record<string, string>)

                // Créer companiesSupportWithLocation sous forme [{ locationId, email_support, companyId }]
                const updatedCompaniesWithEmail = business.map((location) => ({
                    locationId: location?.id,
                    email_support: emailMap[String(location?.companyId)] || "", // Associer email avec locationId
                    companyId: String(location?.companyId), // Ajouter companyId
                }))

                setCompaniesSupportWithLocation(updatedCompaniesWithEmail)
            } catch (error) {
                console.error("Erreur lors de la récupération des emails :", error)
            }

            update({ businesses: mappedEntities, businessesTimezone: formattedTimezone(mappedEntities) })
            updateCampaign({ locations: business })
            return mappedEntities
        },
        [profile?.uid, update]
    )

    return {
        fetchBusinessList,
        companiesSupportWithLocation,
    }
}

export default useBusinessHooks
