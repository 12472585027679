import GPlaceInputAddress from "@/components/widget/autocomplete/address-autocomplete"
import Icon from "@/components/base/Icon"
import FormAction from "@/components/base/form-action"
import BaseInput from "@/components/base/input"
import BaseSelect from "@/components/base/select"
import { MAX_INPUT } from "@/data/limiter"
import useLogic from "@/hooks/companies/form/useLogic"
import { FormValues } from "@/types/companies"
import { Box, Paper, Typography } from "@mui/material"
import { Controller } from "react-hook-form"
interface CompanyFormInput {
    callApi: (data: FormValues) => Promise<any>
    type: "edit" | "create"
}

const CompanyForm = ({ callApi, type }: CompanyFormInput) => {
    const {
        MESSAGE_TEXT,
        countryList,
        stateList,
        provinceList,
        addressValuePlace,
        control,
        zipInputValue,
        isZipValid,
        isSaving,
        nameError,
        handleSubmit,
        onSubmit,
        setAddressValuePlace,
        watch,
        register,
        zipInputHandler,
        handleAddressChange,
        handleCancelButton,
        handleAddressInputValue,
        handleCountrySelected,
        errors,
        isValid,
    } = useLogic({ pageType: type })

    return (
        <form id="edit-company" className="full-height" onSubmit={handleSubmit((data) => onSubmit(data, callApi))}>
            <Box className="inputs-container container-row" sx={{ display: "flex", gap: "16px" }}>
                <Box
                    className="inputs-container"
                    sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "16px" }}
                >
                    <Paper className="inputs-wrapper">
                        <Typography component="h2" className="title">
                            <Icon name="profile" className="title-icon" />
                            {MESSAGE_TEXT.MAIN_INFO}
                        </Typography>
                        <Controller
                            render={({ field }) => (
                                <BaseInput
                                    max={MAX_INPUT.TEXT}
                                    showLabelStar
                                    label={MESSAGE_TEXT.COMPANY_NAME}
                                    required
                                    data-testid="name"
                                    {...field}
                                    error={!!nameError || Boolean(errors?.name)}
                                    helperText={
                                        nameError ? MESSAGE_TEXT.EXIST : errors?.name ? errors?.name?.message : ""
                                    }
                                />
                            )}
                            control={control}
                            name="name"
                        />
                    </Paper>
                    <Paper className="inputs-wrapper">
                        <Typography component="h2" className="title">
                            <Icon name="profile" className="title-icon" />
                            {MESSAGE_TEXT.EMAIL_REPLY_TITLE}
                        </Typography>
                        <Typography component="p" color={"#68738D"}>
                            {MESSAGE_TEXT.EMAIL_REPLY_DETAILS}
                        </Typography>
                        <Controller
                            rules={{
                                validate: (value) => {
                                    if (!value) {
                                        // Si aucune valeur, ne retourne pas d'erreur (champ non requis)
                                        return true
                                    }
                                    // Si une valeur est fournie, valide qu'elle correspond à une adresse email
                                    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
                                    return isValidEmail || "Veuillez saisir une adresse email valide."
                                },
                            }}
                            render={({ field }) => (
                                <BaseInput
                                    {...field}
                                    label={MESSAGE_TEXT.EMAIL_REPLY_TITLE}
                                    data-testid="email-support"
                                />
                            )}
                            control={control}
                            name="email_support"
                        />
                    </Paper>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <Paper className="inputs-wrapper">
                        <Typography component="h2" className="title">
                            <Icon name="address" className="title-icon" />
                            {MESSAGE_TEXT.ADDRESS}
                        </Typography>
                        <Box className="content">
                            <BaseSelect
                                required
                                label={MESSAGE_TEXT.COUNTRY}
                                options={countryList}
                                control={control}
                                formStateError={Boolean(errors?.country_code)}
                                controlName="country_code"
                                dataTestId="country_code"
                                onItemClicked={handleCountrySelected}
                            />
                            <GPlaceInputAddress
                                setField={setAddressValuePlace}
                                field={addressValuePlace}
                                label={MESSAGE_TEXT.ADDRESS}
                                onSelect={handleAddressChange}
                                countryRestrictions={[watch("country_code")]}
                                defaultValue=""
                                dataTestId="address"
                                inputValueChange={handleAddressInputValue}
                            />
                            <BaseInput
                                shrink={!!watch("city")}
                                label={MESSAGE_TEXT.TOWN}
                                register={register("city")}
                                data-testid="city"
                            />

                            {(watch("country_code") === "US" || watch("country_code") === "CA") && (
                                <BaseSelect
                                    control={control}
                                    controlName="province_code"
                                    label={watch("country_code") === "US" ? MESSAGE_TEXT.STATES : MESSAGE_TEXT.PROVINCE}
                                    options={watch("country_code") === "US" ? stateList : provinceList}
                                />
                            )}
                            <BaseInput
                                shrink={!!watch("zip")}
                                label={MESSAGE_TEXT.CODE}
                                register={register("zip")}
                                value={zipInputValue}
                                error={!isZipValid}
                                helperText={!isZipValid && MESSAGE_TEXT.ZIP_INVALID}
                                onInput={zipInputHandler}
                                data-testid="zip"
                            />
                        </Box>
                    </Paper>
                </Box>
            </Box>
            <FormAction
                handleCancel={handleCancelButton}
                isDisabled={!isValid || isSaving}
                isLoading={isSaving}
                isUpdating
            />
        </form>
    )
}

export default CompanyForm
