import { SxProps, Theme } from "@mui/material/styles"

export const modalContainer: SxProps<Theme> = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    bgcolor: "rgba(0, 0, 0, 0.5)", // Background semi-transparent
}

export const paperStyle: SxProps<Theme> = {
    width: "400px",
    p: 3,
    borderRadius: 2,
    position: "relative",
}

export const closeButton: SxProps<Theme> = {
    position: "absolute",
    top: 8,
    right: 8,
}

export const titleStyle: SxProps<Theme> = {
    fontWeight: 600,
    mb: 2,
}

export const subtitleStyle: SxProps<Theme> = {
    mb: 3,
    color: "text.secondary",
}

export const textFieldStyle: SxProps<Theme> = {
    mb: 3,
}

export const cancelButton: SxProps<Theme> = {
    flex: 1,
    bgcolor: "background.paper",
    "&:hover": { bgcolor: "action.hover" },
}

export const saveButton: SxProps<Theme> = {
    flex: 1,
}
